<template>
  <div>
    <div v-if="imgList && imgList.length" class="image-list">
      <ul ref="imgList" class="drag-container">
        <li
          v-for="(item, idx) in imgList"
          :key="idx"
          class="drag-list"
          draggable="true"
        >
          <img
            :src="item"
            alt=""
            @dragover="onDragOver(idx)"
            @dragend="onDragEnd(idx)"
          />
        </li>
      </ul>
    </div>
    <span  class="dialog-footer">
      <el-button type="primary">确 定</el-button>
    </span>
  </div>
</template>

<script>
export default {
  name: "Dialog",
  data() {
    return {
      imgList: [
        require("@/assets/images/BankingServices-1.png"),
        require("@/assets/images/BankingServices-2.png"),
        require("@/assets/images/BankingServices-3.png"),
        require("@/assets/images/BankingServices-4.png"),
        require("@/assets/images/BankingServices-5.png"),
      ],
      index:0,
    };
  },
  methods: {
    onDragOver(idx) {
      event.preventDefault();
      this.index = idx;
    },
    onDragEnd(idx) {
      [this.imgList[idx], this.imgList[this.index]] = [
        this.imgList[this.index],
        this.imgList[idx],
      ];
      this.imgList = [...this.imgList];
    },
  },
};
</script>

<style scoped lang="scss">
// .drag-container {
//   display: flex;
//   justify-content: start;
//   flex-wrap: wrap;
//   margin: 20px;
//   padding-left: 0;
// }

.drag-list {
  width: 200px;
  height: 150px;
  padding: 15px;
  margin-bottom: 10px;
  list-style: none;

  img {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
}
</style>

